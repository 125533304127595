import React, { useState } from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';

import './styles/main';
import './services/url-change-listener';

import HomePage from './pages/home';
import AboutPage from './pages/about';
import PostPage from './pages/post';

import Sidebar from './containers/sidebar';
import MobileMenu from './containers/mobile-menu';

import { history } from './services/history';
import { externalLinks } from './utils/consts';
import FooterLinks from './containers/footer-links';

export default function app() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="app">
          <div className="wrapper">
            <Sidebar />
            <div className="hide-desktop">
              <MobileMenu />
            </div>
            <div id="main-content" className="main-content">
              <div className="page-wrapper">
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/about" component={AboutPage} />
                  <Route exact path="/post/:id" component={PostPage} />
                </Switch>
              </div>
              <div className="footer-content mt3">
                <div className="flex justify-center mb2">
                  <p>
                    A Personal Blog by <a href={externalLinks.github}>Omar 'Shenato' ElGaml</a>
                  </p>
                </div>
                <div className="hide-desktop mt2">
                  <FooterLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
}
