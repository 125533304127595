import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import PostsReducer from './posts/reducer';

const reducers = combineReducers({
  posts: PostsReducer,
});
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers();
// applyMiddleware(...middleware)
// other store enhancers if any

export const store = createStore(reducers, enhancer);
