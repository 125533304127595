import React, { useEffect } from "react";
import Prism from "prismjs";
import marked from "marked";
import "prismjs/components/prism-jsx.min";
import { StyledMarkdown } from "./styled.jsx";

const Markdown = ({ markdown }) => {
  const htmlContet = markdown && marked(markdown);

  useEffect(() => {
    setTimeout(() => {
      Prism.highlightAll();
    }, 0);
  }, []);
  return (
    <StyledMarkdown
      dangerouslySetInnerHTML={{ __html: htmlContet }}
    ></StyledMarkdown>
  );
};
export default Markdown;
