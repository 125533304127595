export const ACTION_TYPES = {
  SET_CURRENT_POST: 'SET_CURRENT_POST',
  SET_POSTS: 'SET_POSTS',
};

const initialState = {
  current: null,
  list: [],
};

export default function(state = initialState, action) {
  let partialState;
  switch (action.type) {
    case ACTION_TYPES.SET_POSTS:
      partialState = { list: action.payload };
      break;
    case ACTION_TYPES.SET_CURRENT_POST:
      partialState = { current: action.payload };
      break;
    default:
      break;
  }
  return partialState != null ? { ...state, ...partialState } : state;
}
