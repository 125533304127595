import React from 'react';
import { internalLinks } from '../../utils/consts';
import './menu-list';
import { Link } from 'react-router-dom';
const MenuLinks = ({ onClick }) => {
  return internalLinks.map(link => (
    <Link onClick={onClick} key={link.link} className="menu-nav-link" to={link.link}>
      {link.name}
    </Link>
  ));
};
export default MenuLinks;
