import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ArticlePreview from "../../components/article-preview";
import { navigate } from "../../services/history";
import client from "../../services/content";
import marked from "marked";
import { siteName } from "../../utils/consts";
import "./home.css";

const mapStateToProps = (state) => ({
  posts: state.posts.list,
});
const dispatchToProps = {};

const HomePage = ({}) => {
  const [posts, setPosts] = useState([]);

  const loadData = async () => {
    const entries = await client.getEntries({
      content_type: "post",
    });
    setPosts(entries);
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="home-page">
      <h3
        onClick={() => navigate("/")}
        className="secondary-font h1 bold mb2 center"
      >
        {siteName}
      </h3>
      <div className="flex justify-center">
        <div className="posts-wrapper flex flex-column justify-center mt2">
          {posts &&
            posts.items &&
            posts.items.map((post) => (
              <ArticlePreview
                key={post.fields.title}
                title={post.fields.title}
                content={post.fields.content}
                date={post.sys.createdAt}
                onClick={() => navigate(`/post/${post.fields.slug}`)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchToProps)(HomePage);
