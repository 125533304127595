import React from 'react';

const DateDisplay = ({ value }) => {
  if (!value) {
    return '';
  }
  const date = new Date(value);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  return `${months[month]} ${day}, ${year}`;
};
export default DateDisplay;
