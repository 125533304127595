import React, { useState } from 'react';
import MenuLinks from '../../components/menu-list';
import './mobile-menu.css';

const MobileMenu = ({}) => {
  const [menuOpen, toggleMenu] = useState(false);
  return (
    <div className={`mobile-menu${menuOpen ? ' open' : ''}`}>
      <div className="mobile-menu-list">
        <MenuLinks onClick={() => toggleMenu(!menuOpen)} />
      </div>
      <button aria-label="Mobile Menu" className="mobile-menu-button" onClick={() => toggleMenu(!menuOpen)}>
        <i className="fas fa-bars"></i>
      </button>
      <div className="circle" />
    </div>
  );
};
export default MobileMenu;
