import React from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';

import './sidebar';
import { externalLinks } from '../../utils/consts';
import MenuLinks from '../../components/menu-list';
import { navigate } from '../../services/history';
import FooterLinks from '../footer-links';

const mapStateToProps = (state) => ({});

const dispatchToProps = {};

const SideBar = ({ ...props }) => {
  return (
    <div className="sidebar">
      <div>
        <div onClick={() => navigate('/')} className="sidebar-header cur-p">
          <LazyLoad height={120}>
            <img src="/assets/me.jpg" />
          </LazyLoad>
        </div>
        <div className="nav-wrapper">
          <MenuLinks />
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default connect(mapStateToProps, dispatchToProps)(SideBar);
