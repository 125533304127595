import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { history, navigate } from '../../services/history';
import client from '../../services/content';
import Markdown from '../../components/markdown';
import DateDisplay from '../../components/date';
import { calculateReadTime } from '../../utils/stringUtils';
import { siteName } from '../../utils/consts';

import './post.css';

const PostPage = ({ match }) => {
  const postSlug = match.params.id;
  const [post, setPost] = useState({});
  useEffect(() => {
    loadData(postSlug, setPost);
    const mainContainer = document.getElementById('main-content');
    if (mainContainer) {
      mainContainer.scrollTo(0, 0);
    }
  }, []);
  async function loadData(postSlug, setPost) {
    const posts = await client.getEntries({
      content_type: 'post',
      'fields.slug': postSlug,
    });
    setPost(posts.items[0]);
  }
  const readTime = post && post.fields && calculateReadTime(post.fields.content);

  return (
    <div className="flex flex-column justify-center items-center">
      <div className="flex flex-column justify-center flex-auto w100 mb2">
        <h3 onClick={() => navigate('/')} className="secondary-font h1 bold mb2 center">
          {siteName}
        </h3>
      </div>
      {post && post.fields && (
        <div className="post-wrapper">
          <h3 className="secondary-font fz-40 c-main mb1 bold">{post.fields.title}</h3>
          <div className="mb3 h5">
            <DateDisplay value={post.sys.createdAt} /> • <span>{readTime} minute read</span>
          </div>
          <div className="mb3">
            <Markdown markdown={post.fields.content} />
          </div>
        </div>
      )}
    </div>
  );
};
export default PostPage;
