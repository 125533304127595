import React, { useEffect } from 'react';
import { truncate } from 'utils/stringUtils';
import './article-preview';
import Markdown from '../markdown';
import DateDisplay from '../date';
import { calculateReadTime } from '../../utils/stringUtils';

const ArticlePreview = ({ title, content, date, onClick }) => {
  const readTime = calculateReadTime(content);
  const readTimeVisualRepresentation = '☕'.repeat(Math.ceil(readTime / 4)).replace(/☕☕☕/gi, '📚');

  return (
    <div className="article-preview my2 cursor-pointer cur-p" onClick={onClick}>
      <h3 className="secondary-font h1 c-main mb1 bold">{title}</h3>
      <div className="mb2 h5">
        <DateDisplay value={date} /> •{' '}
        <span>
          {readTimeVisualRepresentation} {readTime} min read
        </span>
      </div>
      <p className="trimmed-content-wrapper mb1">
        <Markdown markdown={content} />
        <div className="gradient-background"></div>
      </p>
    </div>
  );
};
export default ArticlePreview;
