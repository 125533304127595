import React from 'react';
import { externalLinks } from '../../utils/consts';
import './footer-links.css';

const FooterLinks = ({}) => {
  return (
    <div className="footer-links flex flex-column justify-center items-center p1">
      <div className=" flex justify-center items-center">
        <a aria-label="My Github" className="c-sidebar-text-color mx1" href={externalLinks.github}>
          <i className="fab fa-github"></i>
        </a>
        <a aria-label="My LinkedIn" className="c-sidebar-text-color mx1" href={externalLinks.linkedin}>
          <i className="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>
  );
};
export default FooterLinks;
