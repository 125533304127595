export const siteName = "Shenato's Shack";

export const internalLinks = [
  { name: 'Home', link: '/' },
  { name: 'About', link: '/about' },
];

export const externalLinks = {
  github: 'https://github.com/Shenato',
  linkedin: 'https://www.linkedin.com/in/omar-elgaml',
};
